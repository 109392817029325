import { createContext, useCallback, useContext, useState } from 'react'
import { logEvent } from '../../../utils/firebase'
import { NEW_MONTHLY_SUBSCRIPTION, OLD_IOS_SUBSCRIPTION } from '../constants/productIds'
import { api } from '../utils'
export const PaymentContext = createContext()

const DEV_REVENUS_CAT_AUTH_TOKEN = 'Bearer TmhuQZnVUunBqhidvCeHWJsZKXqJGFaN'
const PROD_REVENUS_CAT_AUTH_TOKEN = 'Bearer strp_GLqDvdpJBHHcIPlVPchqayEWvCD'

const DEV_ENDPOINT = 'https://decormatters-dev.herokuapp.com/parse/functions/'
const PROD_ENDPOINT = 'https://server.decormatters.com/parse/functions/'

const DEFAULT_MEMBERSHIP_MESSAGE = 'Buy with Confidence'

const PaymentProvider = ({ appid, token, userid, ...props }) => {
  const [me, setMe] = useState()

  const [loading, setLoading] = useState(true)
  const [coinPackages, setCoinPackages] = useState([])
  const [membershipPackages, setMembershipPackages] = useState([])
  const [userEventPackages, setUserEventPackages] = useState([])

  const [selectedCoin, setSelectedCoin] = useState()
  const [selectedMembership, setSelectedMembership] = useState()
  const [selectedPackage, setSelectedPackage] = useState()
  const [clientSecret, setClientSecret] = useState()
  const [customerId, setCustomerId] = useState()
  const [bundlesList, setBundlesList] = useState([])
  const [closeMode, setCloseMode] = useState('back')

  const [membershipPerks, setMembershipPerks] = useState([])
  const [membershipMessage, setMembershipMessage] = useState(DEFAULT_MEMBERSHIP_MESSAGE)

  const setExpiration = (expire, isExpired = false) => {
    const exp = Date.parse(expire)
    var ed = new Date(exp)
    const eye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(ed)
    const emo = new Intl.DateTimeFormat('en', { month: 'short' }).format(ed)
    const eda = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(ed)

    if (isExpired) setMembershipMessage(`Expired on ${emo} ${eda}, ${eye}`)
    else setMembershipMessage(`Expires on ${emo} ${eda}, ${eye}`)
  }

  //-------------------------------------------------------

  const checkMembership = async (userid, preselectedPackage) => {
    setLoading(true)

    const cattoken = appid === 3 ? PROD_REVENUS_CAT_AUTH_TOKEN : DEV_REVENUS_CAT_AUTH_TOKEN
    const catoptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cattoken
      }
    }

    const url = 'https://api.revenuecat.com/v1/subscribers/' + userid
    const response = await fetch(url, catoptions)
    const res = await response.json()
    const products = await getProducts(preselectedPackage)
    const today = new Date()

    let myMembership = null

    //-------------------------------------------------------
    // CHECK STRIPE MEMBERSHIP
    //-------------------------------------------------------

    if (res?.subscriber?.subscriptions) {
      if (Object.keys(res.subscriber.subscriptions).length > 0) {
        for (var item in res.subscriber.subscriptions) {
          //check if user own subs match with latest products in stripe
          products.forEach(p => {
            let fixedItem = item === OLD_IOS_SUBSCRIPTION ? NEW_MONTHLY_SUBSCRIPTION : item

            if (p.id === fixedItem) {
              const m = res.subscriber.subscriptions[item]
              const exp = Date.parse(m.expires_date)
              const isExpired = exp - today.getTime() <= 0 ? true : false
              setExpiration(m.expires_date, isExpired)

              if (isExpired == false) {
                myMembership = res.subscriber.subscriptions[item]
                myMembership['product'] = p
              }
            }
          })
        }
      }
    }

    if (myMembership) {
      setMe(myMembership)
      setLoading(false)

      // if (myMembership?.product?.id) {
      //   setSelectedPackage(preselectedPackage || undefined)
      // }

      return
    }

    //-------------------------------------------------------
    // CHECK APPLE MEMBERSHIP
    //-------------------------------------------------------

    if (res?.subscriber?.entitlements) {
      if (Object.keys(res.subscriber.entitlements).length > 0) {
        for (var item in res.subscriber.entitlements) {
          var equivalentName = null
          switch (res.subscriber.entitlements[item].product_identifier) {
            case 'yearlyMember':
              equivalentName = 'Yearly'
              break
            case 'monthlyMember':
              equivalentName = 'Monthly'
              break
            case 'weeklyMember':
              equivalentName = 'Weekly'
              break
            default:
              equivalentName = null
          }
          products.forEach(p => {
            if (p.name === equivalentName) {
              const m = res.subscriber.entitlements[item]
              const exp = Date.parse(m.expires_date)
              const isExpired = exp - today.getTime() <= 0 ? true : false
              setExpiration(m.expires_date, isExpired)

              if (isExpired == false) {
                myMembership = res.subscriber.entitlements[item]
                myMembership['product'] = p
              }
            }
          })
        }
      }
    }

    if (myMembership) {
      setMe(myMembership)
      setLoading(false)

      // if (myMembership?.product?.id) {
      //   setSelectedPackage(preselectedPackage || undefined)
      // }

      return
    }

    setLoading(false)
    return
  }

  const getBundlesList = async stripeProducts => {
    const base = appid === 3 ? PROD_ENDPOINT : DEV_ENDPOINT
    let data = await api(base + 'getItemBundles1', appid, token, '')

    if (data?.result?.itemBundles) {
      const items = data.result.itemBundles.map(item => {
        const stripeProduct = stripeProducts.find(product => product?.metadata?.appleProductId === item.appleProductId)
        item.product = stripeProduct

        return item
      })

      setBundlesList(items)
    }
  }

  const getProducts = async preselectedPackage => {
    // const sdata = IS_DEVELOPMENT ? '/stripe_dev062024.json' : '/stripe062024.json'
    const sdata = '/stripe062024.json'

    const stripeData = await fetch(sdata, {
      headers: {}
    })
    const stripeJson = await stripeData.json()
    const stripeProuctData = stripeJson.products
    const stripePriceData = stripeJson.prices

    if (!stripeProuctData) return []

    getBundlesList(stripeProuctData.data)

    const products = stripeProuctData?.data?.filter(f => f.active === true && f.nickname !== 'bundle')

    products?.forEach((p, i) => {
      for (var x = 0; x < stripePriceData.data.length; x++) {
        if (stripePriceData.data[x].product === p.id && !p['price']) {
          p['price'] = stripePriceData.data[x]
          p['nickname'] = stripePriceData.data[x].nickname
          //if (stripePriceData.data[x].nickname === 'membership') console.log(stripePriceData.data[x].product)
        }
      }

      if (p?.metadata && p?.metadata?.perks) {
        const perks = p?.metadata?.perks?.split(',')
        if (perks) p.perks = perks
      }
    })

    const c = products?.filter(f => f.nickname === 'coins')
    const m = products?.filter(f => f.nickname === 'membership')
    const userEvent = products?.filter(f => f.name?.includes('User Event'))

    // add sort by low price first .sort((a,b)=>a-b)
    m.sort(function (a, b) {
      return b.price.unit_amount - a.price.unit_amount
    })

    c.sort(function (a, b) {
      return a.price.unit_amount - b.price.unit_amount
    })

    setCoinPackages(c)

    setMembershipPackages(m)
    setUserEventPackages(userEvent)

    var cpromo = 0
    c.forEach((p, i) => {
      if (p.metadata && p.metadata.promote) {
        if (p.metadata.promote === 'true' || p.metadata.promote === true) cpromo = i
      }
    })

    setSelectedCoin(c[cpromo])

    var mpromo = 0
    m.forEach((p, i) => {
      if (p.metadata && p.metadata.promote) {
        if (p.metadata.promote === 'true' || p.metadata.promote === true) mpromo = i
      }
    })

    // setSelectedMembership(preselectedPackage || m[mpromo])
    // setSelectedPackage(preselectedPackage || m[mpromo])
    const perks = (preselectedPackage || m[mpromo])?.metadata?.perks?.split(',')
    if (perks) setMembershipPerks(perks)
    return m
  }

  const getPaymentIntentCoins = async product_id => {
    const body = JSON.stringify({ product_id })
    const base = appid === 3 ? PROD_ENDPOINT : DEV_ENDPOINT

    let data = await api(base + 'createStripePaymentIntent1', appid, token, body)
    setClientSecret(data.result.clientSecret)

    return data.result.clientSecret
  }

  const getCheckoutSession = async intent_id => {
    const stripeUpdate = await fetch(`/api/payments/sessions?intentId=${intent_id}`, {
      method: 'GET'
    })
    const result = await stripeUpdate.json()

    return result
  }

  const selectCoinPackage = option => {
    setSelectedCoin(option)
  }

  const selectMembershipPackage = option => {
    setSelectedMembership(option)
  }

  const selectPackage = useCallback(option => {
    if (option?.metadata?.perks) setMembershipPerks(option?.metadata?.perks?.split(','))
    setSelectedPackage(option)
  }, [])

  const createCustomer = async () => {
    const base = appid === 3 ? PROD_ENDPOINT : DEV_ENDPOINT
    let data = await api(base + 'createStripeCustomer1', appid, token, JSON.stringify({}))
    if (data.result.customerId) {
      setCustomerId(data.result.customerId)
      return data.result.customerId
    } else {
      alert('Error happened. Please, try logging out and logging in again.')

      return null
    }
  }

  const createSubscription = async (paymentMethodId, priceId, customer = customerId) => {
    const body = JSON.stringify({
      paymentMethodId,
      customerId: customer,
      priceId
    })
    const base = appid === 3 ? PROD_ENDPOINT : DEV_ENDPOINT
    let data = await api(base + 'createStripeSubscription1', appid, token, body)
    setCustomerId(data.result.subscription)

    logEvent('purchase_made', { type: 'subscription' })

    return data.result.subscription
  }

  const upgradeSubscription = async (subscriptionId, newPlanId) => {
    const stripeUpdate = await fetch(`/api/payments/subscriptions?subscriptionId=${subscriptionId}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      method: 'POST',
      body: `plan=${newPlanId}`
    })
    const result = await stripeUpdate.json()

    setCustomerId(result.customer)
    return result
  }

  const createRevenueCatReceipt = async (userid, subid) => {
    const options = {
      method: 'POST',
      headers: {
        'X-Platform': 'stripe',
        'Content-Type': 'application/json',
        Authorization: appid === 3 ? PROD_REVENUS_CAT_AUTH_TOKEN : DEV_REVENUS_CAT_AUTH_TOKEN
      },
      body: JSON.stringify({
        app_user_id: userid,
        fetch_token: subid
      })
    }
    //const url = 'https://api.revenuecat.com/v1/subscribers/' + userid
    const url = 'https://api.revenuecat.com/v1/receipts'
    const response = await fetch(url, options)
    const result = await response.json()

    return result
  }

  const createRevenueCatPurchaseReceipt = async (userid, data = {}) => {
    const options = {
      method: 'POST',
      headers: {
        'X-Platform': 'stripe',
        'Content-Type': 'application/json',
        Authorization: appid === 3 ? PROD_REVENUS_CAT_AUTH_TOKEN : DEV_REVENUS_CAT_AUTH_TOKEN
      },
      body: JSON.stringify({
        app_user_id: userid,
        ...data
      })
    }

    const url = 'https://api.revenuecat.com/v1/receipts'
    const response = await fetch(url, options)
    const result = await response.json()

    logEvent('purchase_made', { type: 'coins' })

    return result
  }

  return (
    <PaymentContext.Provider
      value={{
        membershipPerks,
        bundlesList,
        coinPackages,
        membershipPackages,
        selectedCoin,
        selectedMembership,
        selectedPackage,
        clientSecret,
        customerId,
        upgradeSubscription,
        createRevenueCatReceipt,
        createRevenueCatPurchaseReceipt,
        getProducts,
        getPaymentIntentCoins,
        selectCoinPackage,
        selectMembershipPackage,
        selectPackage,
        createCustomer,
        createSubscription,
        getCheckoutSession,
        me,
        membershipMessage,
        loading,
        checkMembership,
        userEventPackages,
        closeMode,
        setCloseMode
      }}
      {...props}
    />
  )
}

const usePayment = () => useContext(PaymentContext)
export { PaymentProvider, usePayment }
